// change the theme
$theme-colors: (
  "primary": black,
  "secondary": #3988b5,
);

// Import Bootstrap and its default variables
@import "~bootstrap/scss/bootstrap.scss";
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800;900&display=swap");
// @import url("https://fonts.googleapis.com/css?family=Poppins:200,300,400,500,600,700,800,900&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

.text-renonDark {
  color: black;
}

.text-renonLite {
  color: #3988b5;
}

.greenButton {
  background: #3988b5;
  color: #fff;
  transition: 0.2s;
  text-align: center;
  text-decoration: none;
  display: inline-block;
}
.greenButton:hover {
  color: white;
  border-bottom: 2px solid hsl(238, 47%, 33%);
  background: #3b3f8f;
}
.whiteButton {
  display: inline-block;
  font-size: 1em;
  background: #efefef;
  padding: 10px 30px;
  text-decoration: none;
  font-weight: 500;
  margin-top: 10px;
  color: #3b3f8f;
  letter-spacing: 2px;
  transition: 0.2s;
}
.whiteButton:hover {
  letter-spacing: 6px;
  color: #3988b5;
  border-bottom: 2px solid #3b3f8f;
}

.scrollButton {
  position: fixed;
  right: 5%;
  bottom: 40px;
  height: 20px;
  font-size: 4rem;
  z-index: 1;
  cursor: pointer;
  color: 3b3f8f;
}

.shadeBg {
  overflow: hidden;
  background-color: #f5f5f5;
}

.breathingSpace {
  padding-left: 10%;
  padding-right: 10%;
}
@media (max-width: 786px) {
  .breathingSpace {
    padding-left: 2%;
    padding-right: 2%;
  }
}

.py-3 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.roundImage {
  border-radius: 50%;
}

.h5font {
  font-weight: bold;
  font-size: 1.2rem;
}

.h4font {
  font-size: 1.5rem;
}

.justifyText {
  text-align: justify;
  text-justify: inter-word;
}

.hrLineStyle {
  margin: auto;
  width: 70%;
}
