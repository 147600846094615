.bg-myGray {
  background-color: #efefef;
  font-size: 16px;
}

.img-padding {
  padding-left: 20px;
}

.nav-item .dropdown-menu {
  background-color: #efefef;
  width: auto;
}
.nav-link {
  font-size: 16px !important;
}
.nav-link.active {
  border-bottom: 1px solid #3988b5 !important;
}
.nav-link:hover {
  border-bottom: 1px solid #3988b5;
}
.nav-item.dropdown:hover .dropdown-menu {
  display: block;
  border-bottom: 1px solid #3988b5;
}
.dropdown-item:active {
  color: #fff !important;
  text-decoration: none;
  background-color: #3b3f8f !important;
}
.dropdown-item.active,
.dropdown-item:active {
  color: #fff !important;
  text-decoration: none;
  background-color: #3b3f8f !important;
}
/* .animate-navbar {
  box-shadow: 1px 1px 1px hsla(240, 20%, 8%, 0.973);
  animation: moveDown 0.5s ease-in-out;
} */

@media (max-width: 992px) {
  .nav-item .dropdown-menu {
    text-align: center;
    width: 100%;
  }
  .submenu .nav-link {
    position: relative !important;
    left: 0px;
  }
  .hoverShow .submenu {
    display: none !important;
  }

  .hoverShow:hover + .submenu {
    display: none !important;
  }
}
@media (min-width: 992px) {
  .submenu {
    position: absolute;
    width: 100%;
    left: 100%;
    top: 0px;
    background-color: #efefef;
  }
}
.hoverShow .submenu {
  display: none !important;
}

.hoverShow:hover + .submenu {
  display: block !important;
}
