.customCard {
  display: flex;
  align-items: center;
  box-shadow: 5px 10px 20px 1px rgba(0, 0, 0, 0.253) !important;
}
.customCard:hover {
  transform: scale(1.05);
  transition: 1.5s;
  z-index: 100;
}

.row {
  margin-right: unset !important;
}

.card-img,
.card-img-top {
  width: auto;
}
