section {
  position: relative;
  width: 100%;
  min-height: -webkit-calc(100% - 66px);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  padding: 100px;
  height: 100vh;
}

section video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: -webkit-calc(100vh - 66px);
  object-fit: cover;
  opacity: 0.8;
  pointer-events: none;
}
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: -webkit-calc(100vh - 66px);
  background: rgba(0, 0, 0, 0.1);
  mix-blend-mode: overlay;
}
.text {
  position: relative;
  z-index: 10;
}

.text h2 {
  font-size: 5em;
  font-weight: 800;
  color: #fff;
  line-height: 1em;
  text-transform: uppercase;
}
.text h3 {
  font-size: 4em;
  font-weight: 700;
  color: #fff;
  line-height: 1em;
  text-transform: uppercase;
}
.text p {
  font-size: 1.1em;
  color: #fff;
  margin: 20px 0;
  font-weight: 400;
  max-width: 700px;
}
.text a {
  display: inline-block;
  font-size: 1em;
  background: #fff;
  padding: 10px 30px;
  text-transform: uppercase;
  text-decoration: none;
  font-weight: 500;
  margin-top: 10px;
  color: #004b25;
  letter-spacing: 2px;
  transition: 0.2s;
}
.text a:hover {
  letter-spacing: 6px;
  color: #39b54a;
  border-bottom: 2px solid #004b25;
}

@media (max-width: 991px) {
  .showcase,
  .showcase header {
    padding: 40px;
  }
  .text h2 {
    font-size: 3em;
  }
  .text h3 {
    font-size: 2em;
  }
}

@media (max-width: 300px) {
  .showcase,
  .showcase header {
    padding: 40px;
  }
  .text h2 {
    font-size: 1.3em;
  }
  .text h3 {
    font-size: 1em;
  }
}

section h2 {
  position: relative;
  font-size: 3em;
  color: #000;
}
section p {
  position: relative;
  font-size: 3em;
  color: #000;
}

.corouselStyle {
  position: relative;
  z-index: 10;
}
