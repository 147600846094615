.error {
  border: 2px solid #ff6565;
}

.error-message {
  color: #ff6565;
  padding: 0.5em 0.2em;
  height: 5em;
  position: absolute;
  font-size: 0.8em;
}
label {
  color: #000;
  font-size: 1.1em;
  font-weight: 400;
}
.customContainer {
  background: #f5f5f5;
  height: auto;
  width: 60%;
  margin: 1em auto;
  color: snow;
  -webkit-box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.4);
  box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.4);
}
.halfColFillContainer {
  background: #f5f5f5;
  height: auto;
  width: 95%;
  margin: 1em auto;
  color: snow;
  -webkit-box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.4);
  box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.4);
}
.halfColFillcustomForm {
  text-align: left;
  padding-left: 1em;
  padding-right: 1em;
  padding-top: 2em;
  padding-bottom: 2em;
}
.customForm {
  text-align: left;
  padding-left: 5em;
  padding-right: 5em;
  padding-top: 2em;
  padding-bottom: 2em;
}
@media (max-width: 786px) {
  .customContainer {
    background: #f5f5f5;
    height: auto;
    width: 90%;
    margin: 1em auto;
    color: snow;
    -webkit-box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.4);
    -moz-box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.4);
    box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.4);
  }
}

@media (max-width: 786px) {
  .customForm {
    text-align: left;
    padding-left: 0.5em;
    padding-right: 0.5em;
    padding-top: 1em;
    padding-bottom: 1em;
  }
}
