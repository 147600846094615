.transparent_square {
  width: 5%;
  height: auto;
}

.central_text {
  left: 50%;
  top: 50%;
  transform: translate(-35%, -35%);
  position: absolute;
}

.moon_container {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 20%;
  transform: translate(-35%, -35%);
}

.moon {
  width: 80%;
  height: auto;
  position: relative;
  left: 120%;
}

.moon_container.moon1 {
  transform: translate(-35%, -35%);
}
.moon_container.moon2 {
  transform: translate(-35%, -35%) rotate(45deg);
}
.moon_container.moon3 {
  transform: translate(-35%, -35%) rotate(90deg);
}
.moon_container.moon4 {
  transform: translate(-35%, -35%) rotate(135deg);
}
.moon_container.moon5 {
  transform: translate(-35%, -35%) rotate(180deg);
}
.moon_container.moon6 {
  transform: translate(-35%, -35%) rotate(225deg);
}
.moon_container.moon7 {
  transform: translate(-35%, -35%) rotate(270deg);
}
.moon_container.moon8 {
  transform: translate(-35%, -35%) rotate(315deg);
}

.moon.moon2 {
  transform: rotate(-45deg);
}
.moon.moon3 {
  transform: rotate(-90deg);
}
.moon.moon4 {
  transform: rotate(-135deg);
}
.moon.moon5 {
  transform: rotate(-180deg);
}
.moon.moon6 {
  transform: rotate(-225deg);
}
.moon.moon7 {
  transform: rotate(-270deg);
}
.moon.moon8 {
  transform: rotate(-315deg);
}
